export function DropdowmIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 7H18"
        stroke="#C2C2C2"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M7 12H18"
        stroke="#C2C2C2"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M7 17H18"
        stroke="#C2C2C2"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
}
