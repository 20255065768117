export function ShareIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#5C65FF"
        fillRule="evenodd"
        d="M15.5 8.5a1 1 0 100-2 1 1 0 000 2zm0 1.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM8.5 13a1 1 0 100-2 1 1 0 000 2zm0 1.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM15.5 17.5a1 1 0 100-2 1 1 0 000 2zm0 1.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#5C65FF"
        fillRule="evenodd"
        d="M14.636 8.103a.75.75 0 01-.238 1.033l-4 2.5a.75.75 0 11-.795-1.272l4-2.5a.75.75 0 011.033.239zM14.636 15.897a.75.75 0 00-.238-1.033l-4-2.5a.75.75 0 10-.795 1.272l4 2.5a.75.75 0 001.033-.239z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
