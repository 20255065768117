export function TeamMemberIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0228 6.99751V8.15453C10.0228 8.51511 9.79207 8.83524 9.45 8.94926C8.52359 9.25806 7.52202 9.25806 6.59562 8.94926C6.25354 8.83524 6.02281 8.51511 6.02281 8.15453V6.97673C6.44279 7.20908 6.96416 7.40986 7.54538 7.49096C8.31095 7.59779 9.17369 7.49484 10.0228 6.99751ZM10.0228 5.77293V5.64071C10.0228 5.53221 9.95891 5.43389 9.85977 5.38983C8.69028 4.87006 7.35533 4.87006 6.18584 5.38983C6.0867 5.43389 6.02281 5.53221 6.02281 5.64071V5.7729C6.35234 6.05258 6.96182 6.39985 7.68358 6.50056C8.38855 6.59893 9.21059 6.46541 10.0228 5.77293ZM5.02281 5.64071C5.02281 5.13702 5.31943 4.68058 5.77971 4.47602C7.20776 3.84133 8.83785 3.84133 10.2659 4.47602C10.7262 4.68058 11.0228 5.13702 11.0228 5.64071V7.75341L12.5912 8.53763C13.6417 9.06287 13.6417 10.562 12.5912 11.0872C9.71535 12.5252 6.33027 12.5252 3.45437 11.0872C2.40388 10.562 2.40388 9.06287 3.45437 8.53763L5.02281 7.75341V5.64071ZM5.14391 8.81089L3.90159 9.43205C3.58814 9.58878 3.58814 10.0361 3.90159 10.1928C6.49596 11.49 9.54966 11.49 12.144 10.1928C12.4575 10.0361 12.4575 9.58878 12.144 9.43205L10.9017 8.81089C10.709 9.31453 10.2992 9.72028 9.76622 9.89795C8.63455 10.2752 7.41106 10.2752 6.27939 9.89795C5.74639 9.72028 5.33662 9.31453 5.14391 8.81089Z"
        fill="#C2C2C2"
      />
    </svg>
  );
}
