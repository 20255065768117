import React from "react";

export const VisitIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 8.25C4.91421 8.25 5.25 8.58579 5.25 9V18C5.25 18.6904 5.80964 19.25 6.5 19.25H18.5C19.1904 19.25 19.75 18.6904 19.75 18V9C19.75 8.58579 20.0858 8.25 20.5 8.25C20.9142 8.25 21.25 8.58579 21.25 9V18C21.25 19.5188 20.0188 20.75 18.5 20.75H6.5C4.98122 20.75 3.75 19.5188 3.75 18V9C3.75 8.58579 4.08579 8.25 4.5 8.25Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2049 3.86749C12.8284 3.68816 12.3791 3.84359 12.2015 4.21464C12.0238 4.58569 12.185 5.03187 12.5615 5.2112L14.8244 6.28906C13.3319 6.4684 12.315 7.23976 11.6775 8.18848C10.975 9.23382 10.75 10.461 10.75 11.2854V16.9997C10.75 17.4139 11.0858 17.7497 11.5 17.7497C11.9142 17.7497 12.25 17.4139 12.25 16.9997V11.2854C12.25 10.6811 12.425 9.76549 12.9225 9.02512C13.3897 8.32984 14.1561 7.75775 15.472 7.74974L14.3177 10.1775C14.141 10.549 14.3034 10.9947 14.6804 11.1731C15.0573 11.3514 15.5061 11.1948 15.6828 10.8233L17.3901 7.23243C17.6839 6.61438 17.4149 5.87281 16.7886 5.57449L13.2049 3.86749Z"
        fill="white"
      />
    </svg>
  );
};
