
import React from "react"

interface StyledProps {
    width?: number | string;
    height?: number | string;
    color?: string;
}

const LockIcon = ({ height = 24, width = 24, color = "#000" }: StyledProps) => {
    return (
        <svg
            fill={color}
            height={height}
            width={width}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 485.00 485.00"
            xmlSpace="preserve"
        >
            <g id="SVGRepo_bgCarrier" strokeWidth={0} />
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
            <g id="SVGRepo_iconCarrier">
                {" "}
                <g>
                    {" "}
                    <path d="M345,175v-72.5C345,45.981,299.019,0,242.5,0S140,45.981,140,102.5V175H70v310h345V175H345z M170,102.5 c0-39.977,32.523-72.5,72.5-72.5S315,62.523,315,102.5V175H170V102.5z M385,455H100V205h285V455z" />{" "}
                    <path d="M227.5,338.047v53.568h30v-53.569c11.814-5.628,20-17.682,20-31.616c0-19.299-15.701-35-35-35c-19.299,0-35,15.701-35,35 C207.5,320.365,215.686,332.42,227.5,338.047z" />{" "}
                </g>{" "}
            </g>
        </svg>

    )
}

export { LockIcon }