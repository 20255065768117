import styled from "@emotion/styled";
import { CloseIcon } from "../../../assets";

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  position: "absolute",
  top: "20px",
  right: "30px",
  cursor: "pointer",
  padding: 6,
  borderRadius: 5,
  opacity: 0.6,
}));
export const ModalCloseButton = ({
  handleCloseModal,
}: {
  handleCloseModal: (e: any) => void;
}) => {
  return <StyledCloseIcon onClick={handleCloseModal} />;
};
