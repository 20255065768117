export function UserIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.3335 8.5C13.1619 8.5 13.8335 7.82843 13.8335 7C13.8335 6.17157 13.1619 5.5 12.3335 5.5C11.5051 5.5 10.8335 6.17157 10.8335 7C10.8335 7.82843 11.5051 8.5 12.3335 8.5ZM12.3335 10C13.9904 10 15.3335 8.65685 15.3335 7C15.3335 5.34315 13.9904 4 12.3335 4C10.6766 4 9.3335 5.34315 9.3335 7C9.3335 8.65685 10.6766 10 12.3335 10Z"
        fill="#464646"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.8335 12.5H11.8335C10.1766 12.5 8.8335 13.8431 8.8335 15.5V17C8.8335 17.8284 9.50507 18.5 10.3335 18.5H14.3335C15.1619 18.5 15.8335 17.8284 15.8335 17V15.5C15.8335 13.8431 14.4904 12.5 12.8335 12.5ZM11.8335 11C9.34821 11 7.3335 13.0147 7.3335 15.5V17C7.3335 18.6569 8.67664 20 10.3335 20H14.3335C15.9903 20 17.3335 18.6569 17.3335 17V15.5C17.3335 13.0147 15.3188 11 12.8335 11H11.8335Z"
        fill="#464646"
      />
    </svg>
  );
}
