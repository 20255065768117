const SearchIcon= ({
  width,
  height,
  color,
}: {
  width?: number;
  height?: number;
  color?: string;
}) => {
  return (
    <svg
      width={width ? width : 24}
      height={height ? height : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13 16.5C16.0376 16.5 18.5 14.0376 18.5 11C18.5 7.96243 16.0376 5.5 13 5.5C9.96243 5.5 7.5 7.96243 7.5 11C7.5 14.0376 9.96243 16.5 13 16.5ZM13 18C16.866 18 20 14.866 20 11C20 7.13401 16.866 4 13 4C9.13401 4 6 7.13401 6 11C6 14.866 9.13401 18 13 18Z"
      
        fill={color ? color : "#464646"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.53033 16.4697C7.82322 16.7626 7.82322 17.2374 7.53033 17.5303L5.53033 19.5303C5.23744 19.8232 4.76256 19.8232 4.46967 19.5303C4.17678 19.2374 4.17678 18.7626 4.46967 18.4697L6.46967 16.4697C6.76256 16.1768 7.23744 16.1768 7.53033 16.4697Z"
        fill={color ? color : "#464646"}

      />
    </svg>
  );
}
export default SearchIcon;