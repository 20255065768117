export function EarthIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill="#464646"
        fillRule="evenodd"
        d="M4.67 10.421c.073.154.15.307.232.458a4.965 4.965 0 01-1.297-.489l-.098-.225c.369.108.754.198 1.164.256zm1.18.105c.09.159.187.316.29.472a4.979 4.979 0 001.876-.421 12.51 12.51 0 01-.066-.248c-.786.17-1.46.217-2.1.197zm1.875-1.258c-.92.207-1.692.26-2.368.219a8.674 8.674 0 01-.521-2.107 12.213 12.213 0 002.683-.058c.03.639.094 1.291.206 1.946zm2.672-.886a18.41 18.41 0 01-1.702.626c-.103-.626-.16-1.25-.183-1.864a11.205 11.205 0 002.48-.87 4.972 4.972 0 01-.595 2.108zm-6.158.943a9.053 9.053 0 01-1.202-.373 11.493 11.493 0 01-.51-2.01c.407.122.838.225 1.288.304.067.69.203 1.39.424 2.08zm-2.52-.91a9.67 9.67 0 01-.286-1.871 8.072 8.072 0 01-.421-.198c.05.726.254 1.409.58 2.017l.127.052zm-.717.906A5.972 5.972 0 010 6a5.994 5.994 0 012.659-4.984L2.667 1l.012.002a6 6 0 110 9.995L2.667 11l-.007-.015a6.033 6.033 0 01-1.646-1.647A1.103 1.103 0 011 9.333l.002-.012zm6.499-3.007a11.085 11.085 0 01-2.734.055c-.006-.374.008-.744.04-1.107.046-.516.128-1.013.235-1.482.82.064 1.705.078 2.633 0-.097.78-.166 1.636-.174 2.534zM6.14 1.002a8.16 8.16 0 00-.824 1.795c.787.053 1.628.054 2.505-.035.079-.489.164-.932.242-1.318a4.977 4.977 0 00-1.923-.442zm2.363 5.117c.018-.873.095-1.707.196-2.464.5-.079 1.01-.187 1.525-.331.352.554.598 1.183.71 1.856a10.16 10.16 0 01-2.43.939zm.357-3.505c.225-.042.452-.09.68-.145a5.029 5.029 0 00-.564-.487c-.039.2-.078.41-.116.632zm-5.697-.087c.358.063.737.123 1.134.172.18-.583.396-1.117.631-1.584-.477.104-.93.277-1.345.507-.15.306-.29.606-.42.905zm.878 1.148a23.77 23.77 0 01-1.24-.197 7.134 7.134 0 00-.366 2.384c.406.141.853.263 1.33.358a12.071 12.071 0 01.275-2.545zM1.436 5.439c.006-.118.014-.237.024-.355.05-.627.141-1.175.27-1.687a4.968 4.968 0 00-.67 1.832c.116.071.242.142.376.21z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
