export function ExitIcon() {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 4.5H12C10.6193 4.5 9.5 5.61929 9.5 7V16C9.5 17.3807 10.6193 18.5 12 18.5H17C18.3807 18.5 19.5 17.3807 19.5 16V7C19.5 5.61929 18.3807 4.5 17 4.5ZM12 3C9.79086 3 8 4.79086 8 7V16C8 18.2091 9.79086 20 12 20H17C19.2091 20 21 18.2091 21 16V7C21 4.79086 19.2091 3 17 3H12Z"
        fill="#C2C2C2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 7.25C14.9142 7.25 15.25 7.58579 15.25 8V10C15.25 10.5367 15.0952 11.325 14.6087 12.0007C14.0946 12.7147 13.2444 13.25 12 13.25H5.81066L7.03033 14.4697C7.32322 14.7626 7.32322 15.2374 7.03033 15.5303C6.73744 15.8232 6.26256 15.8232 5.96967 15.5303L3.46967 13.0303C3.17678 12.7374 3.17678 12.2626 3.46967 11.9697L5.96967 9.46967C6.26256 9.17678 6.73744 9.17678 7.03033 9.46967C7.32322 9.76256 7.32322 10.2374 7.03033 10.5303L5.81066 11.75H12C12.7556 11.75 13.1554 11.452 13.3913 11.1243C13.6548 10.7583 13.75 10.2966 13.75 10V8C13.75 7.58579 14.0858 7.25 14.5 7.25Z"
        fill="#C2C2C2"
      />
    </svg>
  );
}
