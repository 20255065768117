export function CopyMemberIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18 9.5H11C10.1716 9.5 9.5 10.1716 9.5 11V18C9.5 18.8284 10.1716 19.5 11 19.5H18C18.8284 19.5 19.5 18.8284 19.5 18V11C19.5 10.1716 18.8284 9.5 18 9.5ZM11 8C9.34315 8 8 9.34315 8 11V18C8 19.6569 9.34315 21 11 21H18C19.6569 21 21 19.6569 21 18V11C21 9.34315 19.6569 8 18 8H11Z"
        fill="#5C65FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.25 6C3.25 4.48122 4.48122 3.25 6 3.25H13C14.5188 3.25 15.75 4.48122 15.75 6L14.25 6C14.25 5.30964 13.6904 4.75 13 4.75H6C5.30964 4.75 4.75 5.30964 4.75 6V13C4.75 13.6904 5.30964 14.25 6 14.25V15.75C4.48122 15.75 3.25 14.5188 3.25 13V6Z"
        fill="#5C65FF"
      />
    </svg>
  );
}
