import React from "react";

export const TrashIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.498 2.71006C11.473 2.38506 12.5271 2.38506 13.5021 2.71006L14.5534 3.0605C15.268 3.2987 15.75 3.96744 15.75 4.7207V5.24994H18C18.4143 5.24994 18.75 5.58572 18.75 5.99994C18.75 6.41415 18.4143 6.74994 18 6.74994H6.00004C5.58583 6.74994 5.25004 6.41415 5.25004 5.99994C5.25004 5.58572 5.58583 5.24994 6.00004 5.24994H8.25004V4.7207C8.25004 3.96744 8.73205 3.2987 9.44665 3.0605L10.498 2.71006ZM9.75004 5.24994H14.25V4.7207C14.25 4.61309 14.1812 4.51756 14.0791 4.48353L13.0278 4.13309C12.3607 3.91072 11.6394 3.91072 10.9723 4.13309L9.92099 4.48353C9.8189 4.51756 9.75004 4.61309 9.75004 4.7207V5.24994ZM5.93507 8.25276C6.34773 8.21688 6.71134 8.52231 6.74723 8.93497L7.50895 17.6949C7.61006 18.8576 8.5834 19.7499 9.7505 19.7499H14.2496C15.4167 19.7499 16.39 18.8576 16.4911 17.6949L17.2529 8.93497C17.2887 8.52231 17.6524 8.21688 18.065 8.25276C18.4777 8.28864 18.7831 8.65225 18.7472 9.06491L17.9855 17.8248C17.817 19.7626 16.1947 21.2499 14.2496 21.2499H9.7505C7.80534 21.2499 6.1831 19.7626 6.01459 17.8248L5.25286 9.06491C5.21698 8.65225 5.52242 8.28864 5.93507 8.25276Z"
        fill="#C2C2C2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 8.25C10.4142 8.25 10.75 8.58579 10.75 9V17C10.75 17.4142 10.4142 17.75 10 17.75C9.58579 17.75 9.25 17.4142 9.25 17V9C9.25 8.58579 9.58579 8.25 10 8.25ZM14 8.25C14.4142 8.25 14.75 8.58579 14.75 9V17C14.75 17.4142 14.4142 17.75 14 17.75C13.5858 17.75 13.25 17.4142 13.25 17V9C13.25 8.58579 13.5858 8.25 14 8.25Z"
        fill="#C2C2C2"
      />
    </svg>
  );
};
