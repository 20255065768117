export function ArrowRight() {
  return (
    <svg
      width="5"
      height="8"
      viewBox="0 0 5 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.21967 7.78033C-0.0732231 7.48744 -0.073223 7.01256 0.21967 6.71967L2.93934 4L0.21967 1.28033C-0.0732225 0.987436 -0.0732225 0.512563 0.21967 0.219669C0.512564 -0.0732235 0.987437 -0.0732234 1.28033 0.21967L4.53033 3.46967C4.82322 3.76256 4.82322 4.23744 4.53033 4.53033L1.28033 7.78033C0.987437 8.07322 0.512563 8.07322 0.21967 7.78033Z"
        fill="#F5F5FF"
      />
    </svg>
  );
}
