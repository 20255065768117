const ThunderIcon = ({
  width,
  height,
  color,
}: {
  width?: number;
  height?: number;
  color?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 13}
      height={height ? height : 16}
      fill={color ? color : "#464646"}
      viewBox="0 0 13 16"
    >
      <path
        fill={color ? color : "#464646"}
        fillRule="evenodd"
        d="M1.85.626A.75.75 0 012.5.25H8a.75.75 0 01.685.445l4 9A.75.75 0 0112 10.75H7.338l.407 3.667a.75.75 0 01-1.33.552l-6-7.5A.75.75 0 011 6.25h3.694L1.852 1.378A.75.75 0 011.85.626zM3.806 1.75l2.842 4.872A.75.75 0 016 7.75H2.56l3.409 4.26-.214-1.927A.75.75 0 016.5 9.25h4.346l-3.333-7.5H3.806z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default ThunderIcon;
