export function EmptyMemberIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.3984 26.7229L43.0607 9.06066C43.6464 8.47487 43.6464 7.52513 43.0607 6.93934C42.4749 6.35355 41.5251 6.35355 40.9393 6.93934L4.93934 42.9393C4.35355 43.5251 4.35355 44.4749 4.93934 45.0607C5.52513 45.6464 6.47487 45.6464 7.06066 45.0607L22.2229 29.8984C21.7601 30.8332 21.5 31.8862 21.5 33V38C21.5 40.2091 23.2909 42 25.5 42H33.5C35.7091 42 37.5 40.2091 37.5 38V33C37.5 29.134 34.366 26 30.5 26H28.5C27.3862 26 26.3332 26.2601 25.3984 26.7229ZM17.5 15C18.6046 15 19.5 14.1046 19.5 13C19.5 11.8954 18.6046 11 17.5 11C16.3954 11 15.5 11.8954 15.5 13C15.5 14.1046 16.3954 15 17.5 15ZM17.5 18C20.2614 18 22.5 15.7614 22.5 13C22.5 10.2386 20.2614 8 17.5 8C14.7386 8 12.5 10.2386 12.5 13C12.5 15.7614 14.7386 18 17.5 18ZM30.5 29H28.5C26.2909 29 24.5 30.7909 24.5 33V38C24.5 38.5523 24.9477 39 25.5 39H33.5C34.0523 39 34.5 38.5523 34.5 38V33C34.5 30.7909 32.7091 29 30.5 29Z"
        fill="#C2C2C2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.8655 14.0132C29.7448 14.0044 29.6229 14 29.5 14C26.7386 14 24.5 16.2386 24.5 19C24.5 19.1229 24.5044 19.2448 24.5132 19.3655L29.8655 14.0132ZM25.7802 22.3412C26.6955 23.3595 28.0229 24 29.5 24C32.2614 24 34.5 21.7614 34.5 19C34.5 17.5229 33.8595 16.1955 32.8412 15.2802L30.7122 17.4091C31.191 17.7745 31.5 18.3512 31.5 19C31.5 20.1046 30.6046 21 29.5 21C28.8512 21 28.2745 20.691 27.9091 20.2122L25.7802 22.3412Z"
        fill="#C2C2C2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.9475 21.9312C20.5944 21.0156 18.9758 20.5 17.279 20.5C13.2589 20.5 10 23.7589 10 27.779V31.5333C10 32.225 10.1414 32.8836 10.3968 33.4819L13 30.8787V27.779C13 25.4158 14.9158 23.5 17.279 23.5C18.1619 23.5 19.0117 23.7182 19.7639 24.1147L21.9475 21.9312ZM14.6471 33.4742L12.3597 35.7616C13.1175 36.2298 14.0105 36.5 14.9667 36.5H19.5V33.5H14.9667C14.8579 33.5 14.7511 33.4912 14.6471 33.4742ZM21.9172 26.2042L24.0889 24.0325C24.3267 24.3702 24.5411 24.7281 24.7293 25.1046L24.8416 25.3292L22.1584 26.6708L22.046 26.4462C22.005 26.3641 21.962 26.2834 21.9172 26.2042Z"
        fill="#C2C2C2"
      />
    </svg>
  );
}
