export function ChatIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.13146 10.9262L6.45641 11.0949C6.93553 11.3436 7.48019 11.4848 8.06222 11.4848C9.99101 11.4848 11.5546 9.92119 11.5546 7.99239C11.5546 6.0636 9.99101 4.5 8.06222 4.5C6.13342 4.5 4.56983 6.0636 4.56983 7.99239C4.56983 8.43932 4.65304 8.86326 4.80347 9.25204L4.94793 9.62541L4.88713 10.0211L4.74807 10.9262H5.76533H6.13146ZM3.00403 12.4H6C6.13829 12.4 6.29129 12.4393 6.39642 12.4672C6.44959 12.4813 6.5076 12.4974 6.56628 12.5138L6.56646 12.5138L6.59435 12.5216C6.66396 12.541 6.73691 12.5611 6.81467 12.5815C7.12453 12.6626 7.50427 12.745 7.94217 12.7661C8.80345 12.8075 9.9313 12.6146 11.1877 11.6096C12.281 10.7349 12.7008 9.40398 12.7125 8.05815C12.7242 6.71137 12.3243 5.44645 11.8924 4.79008C10.9766 3.69589 9.60068 3 8.06222 3C5.305 3 3.06983 5.23517 3.06983 7.99239C3.06983 8.62736 3.18837 9.23464 3.40453 9.79332L3.23047 10.9262L3.00403 12.4ZM13.1268 5.00205C13.4814 5.84199 13.7223 6.94291 13.7125 8.06685C13.6992 9.59601 13.219 11.2651 11.8124 12.3904C10.3504 13.56 8.97823 13.8171 7.89408 13.7649C7.35928 13.7392 6.90496 13.6388 6.56143 13.5489C6.47584 13.5265 6.39674 13.5046 6.32625 13.485L6.29746 13.477C6.23804 13.4604 6.18693 13.4462 6.14037 13.4339C6.05797 13.412 6.01762 13.4043 6.00329 13.4015L6.00328 13.4015C5.99571 13.4001 5.99541 13.4 6 13.4H5.01277C5.24883 17.5756 8.70967 20.8889 12.9444 20.8889C13.9549 20.8889 14.9214 20.7002 15.8105 20.3561L18.4997 20.7693L19.9997 20.9998V19.4822V16.5999C20.5678 15.5057 20.8888 14.2626 20.8888 12.9444C20.8888 8.61782 17.4301 5.09901 13.1268 5.00205Z"
        fill="#F5F5FF"
      />
    </svg>
  );
}
