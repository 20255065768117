import React from "react";

export const PromotionCrownIcon = () => {
  return (
    <svg
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.748 23.5706L25.808 21.4827L19.1345 15.4767L15.9611 21.727L24.748 23.5706ZM25.4564 25.0479L27.4188 21.1828L18.7431 13.375L14.0334 22.6513L21.8617 24.2937L20.6631 26.6545L21.8226 27.2432L23.1797 24.5703L25.4564 25.0479Z"
        fill="#5C65FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6408 14.7082L15.8797 12.3771L14.6435 12.7808L15.4046 15.1118L16.6408 14.7082ZM15.1396 16.6493L12.8206 15.4718L12.2318 16.6314L14.5509 17.8088L15.1396 16.6493ZM13.2698 19.3165L10.9388 20.0776L11.3424 21.3138L13.6734 20.5527L13.2698 19.3165Z"
        fill="#5C65FF"
      />
    </svg>
  );
};
