export function CreateActivityIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5 3.75C16.5 3.33579 16.1642 3 15.75 3C15.3358 3 15 3.33579 15 3.75V5.5H13.25C12.8358 5.5 12.5 5.83579 12.5 6.25C12.5 6.66421 12.8358 7 13.25 7H15V8.75C15 9.16421 15.3358 9.5 15.75 9.5C16.1642 9.5 16.5 9.16421 16.5 8.75V7H18.25C18.6642 7 19 6.66421 19 6.25C19 5.83579 18.6642 5.5 18.25 5.5H16.5V3.75Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.09965 5.87645C8.23341 5.64358 8.48146 5.5 8.75001 5.5H11.25C11.6642 5.5 12 5.83579 12 6.25C12 6.66421 11.6642 7 11.25 7H10.0558L12.8978 11.8721C13.0332 12.1041 13.0341 12.3907 12.9004 12.6235C12.7666 12.8564 12.5186 13 12.25 13H8.39248L11.958 17.114L11.5146 14.8971C11.4705 14.6768 11.5276 14.4483 11.67 14.2745C11.8125 14.1007 12.0253 14 12.25 14H15.7894L15.0224 10.9319C14.9219 10.5301 15.1663 10.1229 15.5681 10.0224C15.97 9.92193 16.3772 10.1663 16.4776 10.5681L17.4776 14.5681C17.5336 14.7922 17.4833 15.0295 17.3412 15.2116C17.199 15.3936 16.981 15.5 16.75 15.5H13.1649L13.9854 19.6029C14.0522 19.9367 13.8858 20.2736 13.5801 20.4235C13.2744 20.5733 12.9062 20.4984 12.6832 20.2412L6.18324 12.7412C5.99093 12.5193 5.9458 12.2056 6.06777 11.9385C6.18974 11.6714 6.45637 11.5 6.75001 11.5H10.9442L8.10218 6.6279C7.96686 6.39594 7.9659 6.10932 8.09965 5.87645Z"
        fill="white"
      />
    </svg>
  );
}
