import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ActivityCreateData,
  IPromotionCodeCreate,
  ITicketCreate,
} from "../../types/activity";
import dayjs from "dayjs";
import { standardTimeAMPM } from "../../utils/date";

export interface EditState {
  imageFile: File | null;
  giphyImages: string[];
  giphyImageLink: string;
  userDescription: string;
  activityData: ActivityCreateData;
}

type SingleActivityKey<T> = {
  [K in keyof T]: Record<string, unknown>;
}[keyof T];

const initialState: EditState = {
  imageFile: null,
  giphyImages: [],
  giphyImageLink: "",
  userDescription: "",
  activityData: {
    doerId: "",
    name: "",
    capacity: 0,
    coverPhoto: ["/create_assets/defaultImage.png"],
    description: "",
    imageUrl: ["/create_assets/defaultImage.png"],
    flatFeedId: "",
    verb: "activity",
    taggedPeople: [],
    _likes: [],
    inviteOnly: false,
    chatEnabled: true,
    virtualLocationLink: "",
    title: "",
    price: 0,
    createdAt: "",
    tipEnabled: true,
    //@ts-ignore
    startDate: new Date(dayjs(new Date().setDate(new Date().getDate() + 1))),
    startDateString: "",
    startTime: `${new Date().getHours() + 1}:00`,
    startTimeV2: {
      label: standardTimeAMPM(`${new Date().getHours() + 1}:00`),
      value: { h: new Date().getHours() + 1, m: 0 },
    },
    //@ts-ignore
    endDate: new Date(dayjs(new Date().setDate(new Date().getDate() + 1))),
    endDateString: "",
    endTime: `${new Date().getHours() + 3}:00`,
    endTimeV2: {
      label: standardTimeAMPM(`${new Date().getHours() + 3}:00`),
      value: { h: new Date().getHours() + 3, m: 0 },
    },
    type: "normal",
    isPost: false,
    superDoerName: "",
    superDoerId: "",
    superDoer: {},
    foreignId: "",
    activityRefId: "",
    likeCount: "",
    commentCount: "",
    repeat: {},
    location: {
      coarse: "",
      fine: "",
      lat: 0,
      long: 0,
    },
    ticket: [
      {
        id: "",
        name: "General Admission",
        capacity: 0,
        attendees: [],
        attendList: [],
        description: "",
        // inviteOnly: false,
        // taggedPeople: [],
        // _likes: [],
        // flatFeedId: "",
        // verb: "activity",
        // imageUrl: ["/create_assets/defaultImage.png"],
        // chatEnabled: true,
        // virtualLocationLink: "",
        // title: "",
        // createdAt: "",
        // tipEnabled: true,
        price: 0,
        //@ts-ignore
        startDate: new Date(dayjs(new Date().setDate(new Date().getDate() + 1))
        ),
        startDateString: "",
        startTime: `${new Date().getHours() + 1}:00`,
        startTimeV2: {
          label: standardTimeAMPM(`${new Date().getHours() + 1}:00`),
          value: { h: new Date().getHours() + 1, m: 0 },
        },
        //@ts-ignore
        endDate: new Date(dayjs(new Date().setDate(new Date().getDate() + 1))),
        endDateString: "",
        endTime: `${new Date().getHours() + 3}:00`,
        endTimeV2: {
          label: standardTimeAMPM(`${new Date().getHours() + 3}:00`),
          value: { h: new Date().getHours() + 3, m: 0 },
        },
        type: "normal",
        isPost: false,
        superDoerName: "",
        superDoerId: "",
        superDoer: {},
        foreignId: "",
        activityRefId: "",
        likeCount: "",
        commentCount: "",
        repeat: {},
        location: {
          coarse: "",
          fine: "",
          lat: 0,
          long: 0,
        },
        ticket: [
          {
            id: "",
            name: "General Admission",
            capacity: 0,
            attendees: [],
            attendList: [],
            description: "",
            filledCapacity: 0,
            isFree: true,
            price: 0,
            minBuyAmount: 1,
            maxBuyAmount: null,
            isUnlimited: true,
            // @ts-ignore
            ticketEnding: new Date(dayjs(new Date().setDate(new Date().getDate() + 1))
            ).toISOString(),
            // @ts-ignore
            ticketStart: new Date(dayjs(new Date().setDate(new Date().getDate() + 1))
            ).toISOString(),
          },
        ],
        object: "mainActivity",
        justFriends: false,
        isVirtual: false,
        isLocation: false,
        isFree: true,
        isPrivate: false,
        ownLikeReaction: [],
        ownCommentReaction: [],
        promoCodes: [],
        hasDonation: false,
        hasTips: false,
        isCoverPhotoVideo: false,
        toTarget: [],
        isRepeated: false,
        isReOccurring: false,
        salesBoostModel: {
          isPromotion: false,
          isPeoplePlus: false,
          isTimeStorm: false,
        },
        isNeedSponsor: false,
        expectedAttendees: 0,
        whySponsor: "",
        documents: [],
        demoGraphics: [],
        packages: [],
        stripeStatus: "",
      },
    ],
    object: "mainActivity",
    justFriends: false,
    isVirtual: false,
    isLocation: false,
    isFree: true,
    isPrivate: false,
    ownLikeReaction: [],
    ownCommentReaction: [],
    promoCodes: [],
    hasDonation: false,
    hasTips: false,
    isCoverPhotoVideo: false,
    toTarget: [],
    isRepeated: false,
    isReOccurring: false,
    salesBoostModel: {
      isPromotion: false,
      isPeoplePlus: false,
      isTimeStorm: false,
    },
  },
};

export const EditActivitySlice = createSlice({
  name: "edit",
  initialState: initialState,
  reducers: {
    // setActivity : (state, action : PayloadAction<IActivity>) => {
    //     state.activityData = action.payload;
    // },
    changeUserDescription: (state, action: PayloadAction<string>) => {
      let temp = action.payload;
      state.userDescription = temp;
    },
    changeGiphyImages: (state, action: PayloadAction<string[]>) => {
      let temp = action.payload;
      state.giphyImages = temp;
    },
    setGiphyImage: (state, action: PayloadAction<string>) => {
      let temp = action.payload;
      state.giphyImageLink = temp;
    },
    changeItem: (
      state,
      action: PayloadAction<SingleActivityKey<ActivityCreateData>>
    ) => {
      const payload = action.payload;
      state.activityData = { ...state.activityData, ...payload };
    },
    setTicketAction: (state, action: PayloadAction<ITicketCreate[]>) => {
      let temp = action.payload;
      state.activityData.ticket = temp;
    },
    addTicketAction: (state, action: PayloadAction<ITicketCreate>) => {
      let temp = state.activityData.ticket;
      temp.push(action.payload);
      state.activityData.ticket = temp;
    },
    editTicketAction: (state, action: PayloadAction<ITicketCreate>) => {
      const id = action.payload.id;
      let tickets = [...state.activityData.ticket];
      let index = state.activityData.ticket.findIndex((t) => t.id === id);

      tickets[index] = action.payload;

      state.activityData.ticket = tickets;
    },
    removeTicketAction: (state, action: PayloadAction<ITicketCreate>) => {
      const id = action.payload.id;
      let tickets = state.activityData.ticket.filter((t) => t.id !== id);
      state.activityData.ticket = tickets;
    },
    addPromoAction: (state, action: PayloadAction<IPromotionCodeCreate>) => {
      let temp = state.activityData.promoCodes;
      temp.push(action.payload);
      state.activityData.promoCodes = temp;
    },
    editPromoAction: (state, action: PayloadAction<IPromotionCodeCreate>) => {
      const id = action.payload.id;
      let promoCodes = state.activityData.promoCodes.filter((t) => t.id !== id);
      promoCodes = [...promoCodes, action.payload];
      state.activityData.promoCodes = promoCodes;
    },
    removePromoAction: (state, action: PayloadAction<IPromotionCodeCreate>) => {
      const id = action.payload.id;
      let promoCodes = state.activityData.promoCodes.filter((t) => t.id !== id);
      state.activityData.promoCodes = promoCodes;
    },
    setImageFile: (state, action: PayloadAction<File>) => {
      const file = action.payload;
      state.imageFile = file;
    },
  },
});

export const {
  addTicketAction,
  editTicketAction,
  addPromoAction,
  editPromoAction,
  removePromoAction,
  setTicketAction,
  changeGiphyImages,
  changeUserDescription,
  removeTicketAction,
  changeItem,
  setImageFile,
} = EditActivitySlice.actions;
export default EditActivitySlice.reducer;
