export  function EmptyPostIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.0607 3.06066L34.8263 7.29506C35.5753 8.69736 36 10.2991 36 12V28C36 33.5228 31.5228 38 26 38H10C8.29909 38 6.69736 37.5753 5.29506 36.8263L3.06066 39.0607C2.47487 39.6464 1.52513 39.6464 0.93934 39.0607C0.353553 38.4749 0.353553 37.5251 0.93934 36.9393L36.9393 0.93934C37.5251 0.353553 38.4749 0.353553 39.0607 0.93934C39.6464 1.52513 39.6464 2.47487 39.0607 3.06066ZM24.7869 17.3345L32.5625 9.55877C32.8454 10.3189 33 11.1414 33 12V23.8802L27.473 18.3533C26.7358 17.6161 25.7516 17.2765 24.7869 17.3345ZM7.55877 34.5625L13.6991 28.4222C14.9593 28.9251 16.4528 28.6664 17.473 27.6461L24.6446 20.4746C24.8399 20.2793 25.1564 20.2793 25.3517 20.4746L32.9375 28.0604L33 27.9979V28C33 31.866 29.866 35 26 35H10C9.14138 35 8.31887 34.8454 7.55877 34.5625ZM30.7049 3.17374C29.3026 2.42466 27.7009 2 26 2H10C4.47715 2 0 6.47715 0 12V28C0 29.7009 0.424658 31.3026 1.17374 32.7049L11.3003 22.5784C10.0394 22.0739 8.54438 22.3322 7.52329 23.3533L3 27.8765V12C3 8.13401 6.13401 5 10 5H26C26.8586 5 27.6811 5.15459 28.4412 5.43745L30.7049 3.17374ZM15 14C15 15.6569 13.6569 17 12 17C10.3431 17 9 15.6569 9 14C9 12.3431 10.3431 11 12 11C13.6569 11 15 12.3431 15 14ZM18 14C18 17.3137 15.3137 20 12 20C8.68629 20 6 17.3137 6 14C6 10.6863 8.68629 8 12 8C15.3137 8 18 10.6863 18 14Z"
        fill="#C2C2C2"
      />
    </svg>
  );
}
