import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { BuyingTickets } from "../../types/activity";

export interface TicketState {
  buyingTickets: BuyingTickets | null;
  tip: number;
  tipCustom: number;
  donation: number;
  donationCustom: number;
  promoName: string;
  promoPercentage: boolean;
}

const initialState: TicketState = {
  buyingTickets: null,
  tip: 0,
  tipCustom: 0,
  donation: 0,
  donationCustom: 0,
  promoName: "",
  promoPercentage: true,
};

export const TicketSlice = createSlice({
  name: "ticket",
  initialState: initialState,
  reducers: {
    setPromoName: (state, action: PayloadAction<string>) => {
      state.promoName = action.payload;
    },
    setPromoPercentage: (state, action: PayloadAction<boolean>) => {
      state.promoPercentage = action.payload;
    },
    setBuyingTickets: (state, action: PayloadAction<BuyingTickets | null>) => {
      state.buyingTickets = action.payload;
    },
    setTip: (state, action: PayloadAction<number>) => {
      state.tip = action.payload;
    },
    setDonation: (state, action: PayloadAction<number>) => {
      state.donation = action.payload;
    },
    setTipCustom: (state, action: PayloadAction<number>) => {
      state.tipCustom = action.payload;
    },
    setDonationCustom: (state, action: PayloadAction<number>) => {
      state.donationCustom = action.payload;
    },
  },
});

export const {
  setBuyingTickets,
  setTip,
  setDonation,
  setTipCustom,
  setDonationCustom,
  setPromoPercentage,
  setPromoName,
} = TicketSlice.actions;
export default TicketSlice.reducer;
