export function JoinIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.25 10C7.63071 10 8.75 8.88071 8.75 7.5C8.75 6.11929 7.63071 5 6.25 5C4.86929 5 3.75 6.11929 3.75 7.5C3.75 8.88071 4.86929 10 6.25 10Z"
        fill="#F5F5FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.0049 7.6582H16.7813L16.7813 9.984C16.6889 9.99434 16.5951 9.99965 16.5 9.99965C15.1725 9.99965 14.0867 8.96504 14.0049 7.6582Z"
        fill="#F5F5FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 11C14.067 11 12.5 12.567 12.5 14.5V17C12.5 18.1046 13.3954 19 14.5 19H18.5C19.6046 19 20.5 18.1046 20.5 17V14.5C20.5 12.567 18.933 11 17 11H16Z"
        fill="#F5F5FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.1395 11.25C4.12946 11.25 2.5 12.8795 2.5 14.8895V16.7667C2.5 18.1382 3.61183 19.25 4.98333 19.25H6.5V17.75C6.5 17.75 6.5 17 6.5 16.5C6.5 15.2499 7.5 14 8.14685 13.6766L9.5 13C8.74079 11.8095 7.57123 11.25 6.1395 11.25Z"
        fill="#F5F5FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.5 14H11C9.067 14 7.5 15.567 7.5 17.5V20C7.5 21.1046 8.39543 22 9.5 22H13.5C14.6046 22 15.5 21.1046 15.5 20H14C13 20 11.5 19.5 11.5 18V16.5V15.5V14Z"
        fill="#F5F5FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.129 11.2774C12.2175 11.1587 12.3733 10.9875 12.3733 10.9875C12.3733 10.9875 12.5259 10.8349 12.6085 10.7642C13.0013 10.4275 13.4675 10.1943 13.9649 10.0803C13.7653 8.89939 12.7377 8 11.5 8C10.1193 8 9 9.11929 9 10.5C9 11.8807 10.1193 13 11.5 13L11.5048 13C11.5392 12.3732 11.7015 11.8511 12.129 11.2774Z"
        fill="#F5F5FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.25 2.72619C19.25 2.30139 18.9142 1.95703 18.5 1.95703C18.0858 1.95703 17.75 2.30139 17.75 2.72618L17.75 5.25H15.241C14.8317 5.25 14.5 5.58579 14.5 6C14.5 6.41421 14.8317 6.75 15.241 6.75H17.75L17.75 9.28216C17.75 9.70695 18.0858 10.0513 18.5 10.0513C18.9142 10.0513 19.25 9.70695 19.25 9.28216V6.75H21.759C22.1683 6.75 22.5 6.41421 22.5 6C22.5 5.58579 22.1683 5.25 21.759 5.25H19.25V2.72619Z"
        fill="#F5F5FF"
      />
    </svg>
  );
}
