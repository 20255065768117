import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import moment from "moment";
dayjs.extend(utc);

export interface RemainingTime {
  day: number;
  hour: number;
  minutes: number;
  seconds: number;
}

export interface DateTimeObject {
  year?: number;
  monthNumber?: number | string;
  day?: number;
  dayOfWeekNumber?: number;
  hour?: string;
  minutes?: string;
  seconds?: string;
  month?: string;
  dayOfWeek?: string;
  hourStandard?: string;
  remainingTime: RemainingTime;
  periodStandard: "PM" | "AM";
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const getMonthByIndex = (month: number) => {
  if (month >= 0 && month <= 11) return months[month];
  else throw "Bad Month Index";
};

export const getDayOfWeekByIndex = (day: number) => {
  if (day > 0 && day <= 11) return daysOfWeek[day - 1];
  else if (day === 0) return daysOfWeek[6];
  else throw "Bad Day Of Week Index";
};

const dateConverter: (startDate: Date, timeEnd: Date) => RemainingTime = (
  startDate: Date,
  timeEnd: Date
) => {
  const newStartDate = new Date(startDate);
  const newEndDate = new Date(timeEnd);
  const one_day = 1000 * 60 * 60 * 24;
  let resultDay;
  resultDay = (newEndDate.getTime() - newStartDate.getTime()) / one_day;

  let resultHour = (resultDay - Math.floor(resultDay)) * 24;
  resultHour = resultHour >= 24 ? resultHour - 24 : resultHour;

  let resultMinutes = (resultHour - Math.floor(resultHour)) * 60;
  resultMinutes = resultMinutes >= 60 ? resultMinutes - 60 : resultMinutes;

  let resultSeconds = (resultMinutes - Math.floor(resultMinutes)) * 60;
  resultSeconds = resultSeconds >= 60 ? resultSeconds - 60 : resultSeconds;

  return {
    day: Math.floor(resultDay),
    hour: Math.floor(resultHour),
    minutes: Math.floor(resultMinutes),
    seconds: Math.floor(resultSeconds),
  };
};

export const getRemainingTimeFromNow = (d: Date) => {
  const now = new Date();

  return dateConverter(now, d);
};

export const getDateFromFormat = (dString: string) => {
  const dArray = dString.split("T");
  const date = dArray[0].split("-");
  const time = dArray[1].split(":");
  const d = new Date(
    Date.UTC(
      parseInt(date[0]),
      parseInt(date[1]) - 1,
      parseInt(date[2]),
      parseInt(time[0]),
      parseInt(time[1]),
      parseInt(time[2])
    )
  );

  return d;
};

function convertTo12HourFormat(hour24: number): {
  hour12: number;
  period: "AM" | "PM";
} {
  let hour12 = hour24 % 12;
  if (hour12 === 0) hour12 = 12;
  let period: "AM" | "PM" = hour24 < 12 ? "AM" : "PM";
  return { hour12, period };
}

export const getDateObject: (dString: string) => DateTimeObject = (
  dString: string
) => {
  const d = getDateFromFormat(dString);
  const { hour12, period } = convertTo12HourFormat(d.getHours());

  const hour = d.getHours() < 10 ? `0${d.getHours()}` : `${d.getHours()}`;
  const minutes =
    d.getMinutes() < 10 ? `0${d.getMinutes()}` : `${d.getMinutes()}`;
  const seconds =
    d.getSeconds() < 10 ? `0${d.getSeconds()}` : `${d.getSeconds()}`;
  const months =
    d.getMonth() < 9 ? `0${d.getMonth() + 1}` : `${d.getMonth() + 1}`;
  const year = d.getFullYear();

  const dateTime: DateTimeObject = {
    year: year,
    monthNumber: months,
    month: getMonthByIndex(d.getMonth()),
    day: d.getDate(),
    dayOfWeekNumber: d.getDay() + 1,
    dayOfWeek: getDayOfWeekByIndex(d.getDay()),
    hour: hour12.toString(),
    minutes: minutes,
    seconds: seconds,
    remainingTime: getRemainingTimeFromNow(d),
    hourStandard: hour12.toString(),
    periodStandard: period,
  };

  return dateTime;
};

export function standardTimeString(date: Date | string) {
  let d = dayjs(new Date(new Date(date).toLocaleString()));
  let year = d.year();
  const dString = d.toString();
  let index = dString.indexOf(year.toString());
  if (index < 0) {
    year = d.year() - 1;
    index = dString.indexOf(year.toString());
  }
  const dateString = dString.substring(0, index - 1).trim();
  const month = dString.substring(8, 11);
  const day = dString.substring(5, 7);
  const result = dateString.replace(month, day).replace(day, month);

  return result;
}

function formatDate(inputDate: Date, locale: string = "en-US"): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    month: "short",
    day: "2-digit",
  };

  const formattedDate: string = new Intl.DateTimeFormat(locale, options).format(
    inputDate
  );
  return formattedDate;
}

export function standardDateString(date: Date) {
  return formatDate(date, "en-US");
}

export function standardTimeAMPM(time: string) {
  if (!time) return "";
  const hours = parseInt(time.split(":")[0]);
  const min = time.split(":")[1];
  let tempHours = hours;
  let ap: "am" | "pm" = "am";
  if (hours === 0) {
    tempHours = 12;
    ap = "am";
  }
  if (hours > 12) {
    tempHours = hours - 12;
    ap = "pm";
  }

  let h = tempHours < 10 && tempHours.toString().length < 2 ? "0" : "";
  let m = parseInt(min) < 10 && min.length < 2 ? "0" : "";

  return h + tempHours + ":" + m + min + " " + ap;
}
