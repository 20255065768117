export function ArrowLeftIcon({ width, height }: any) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="26" height="26" rx="7" fill="" />
      <path d="M15 8.5L10 13.5L15 18.5" stroke="#C2C2C2" strokeWidth="2" />
    </svg>
  );
}
