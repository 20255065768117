interface IProps {
  loading: boolean;
}

export const Loader = () => (
  <img
    alt="loading"
    src="/splash/boonoobationloading.gif"
    className="w-32"
    loading="lazy"
  />
);

export default function SplashScreen({ loading }: IProps) {
  if (loading)
    return (
      <div
        className="fixed top-0 right-0 left-0 bottom-0 z-50"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="flex justify-center bg-white w-full h-full items-center">
          <Loader />
        </div>
      </div>
    );
  return null;
}
