import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthenticateMode } from "../../types/activity";

export interface GlobalState {
  activityId: string;
  authentication: any;
  authenticationMode: AuthenticateMode;
}

const initialState: GlobalState = {
  activityId: "",
  authentication: null,
  authenticationMode: "none",
};

export const GlobalSlice = createSlice({
  name: "global",
  initialState: initialState,
  reducers: {
    setAuthenticationMode: (state, action: PayloadAction<AuthenticateMode>) => {
      state.authenticationMode = action.payload;
    },
    setActivityId: (state, action: PayloadAction<string>) => {
      state.activityId = action.payload;
    },
    setAuthentication: (state, action: PayloadAction<any>) => {
      state.authentication = action.payload;
    },
  },
});

export const { setActivityId, setAuthentication, setAuthenticationMode } =
  GlobalSlice.actions;
export default GlobalSlice.reducer;
