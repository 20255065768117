export function DotsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#464646"
        d="M12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 8a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 16a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
      ></path>
    </svg>
  );
}
