export function EmptyActivitiesIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M45.0607 7.06066C45.6464 6.47487 45.6464 5.52513 45.0607 4.93934C44.4749 4.35355 43.5251 4.35355 42.9393 4.93934L6.93934 40.9393C6.35355 41.5251 6.35355 42.4749 6.93934 43.0607C7.52513 43.6464 8.47487 43.6464 9.06066 43.0607L19.209 32.9124L24.8287 39.937C25.2458 40.4584 25.9572 40.6413 26.574 40.3858C27.1909 40.1303 27.5646 39.4979 27.4908 38.8344L26.6759 31.5H36C36.5078 31.5 36.9811 31.2431 37.2578 30.8174C37.5344 30.3916 37.577 29.8548 37.3707 29.3908L32.8661 19.2553L45.0607 7.06066ZM30.5932 21.5281L21.3433 30.778L23.9375 34.0207L23.5092 30.1656C23.4621 29.7416 23.5978 29.3175 23.8823 28.9996C24.1669 28.6817 24.5733 28.5 25 28.5H33.6919L30.5932 21.5281ZM29.3707 11.3908L30.3299 13.5488L28.057 15.8216L27.0252 13.5H19.6116L23.5784 20.3003L21.3849 22.4938L15.7043 12.7558C15.4337 12.2919 15.4318 11.7186 15.6993 11.2529C15.9668 10.7872 16.4629 10.5 17 10.5H28C28.5928 10.5 29.13 10.8491 29.3707 11.3908ZM17.121 25.5H18.3787L21.3787 22.5H14C13.4234 22.5 12.8978 22.8305 12.6481 23.3502C12.3983 23.8699 12.4685 24.4868 12.8287 24.937L15.5456 28.3331L17.6799 26.1987L17.121 25.5Z"
        fill="#C2C2C2"
      />
    </svg>
  );
}
