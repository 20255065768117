import styled from "@emotion/styled";

export const ModalBlur = styled.div(
  ({ top = 0 }: { top?: number | string }) => ({
    position: "absolute",
    top,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#000000CC",
    border: "1px solid #707070",
    // backdropFilter: 'blur(1px)',
    // WebkitBackdropFilter: 'blur(1px)',
    zIndex: 10,
  })
);

export const MModalBodyContainer = styled.div({
  position: "fixed",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#ffffff",
  boxShadow: "2px 2px 5px #ADADAD33",
  border: "0.5px solid #E0E0E0",
  borderRadius: "16px",
  minWidth: "295px",
  minHeight: 50,
  paddingTop: "24px",
  marginTop: "38px",
  maxHeight: "94vh",
  overflow: "scroll",
});

export const ModalTitleBar = styled.div({
  display: "flex",
  padding: "0 16px",
  "& > svg": {
    alignSelf: "center",
    cursor: "pointer",
    "&:hover path": {
      stroke: "#9f0b0b",
    },
  },
});

export const ModalTitle = styled.div({
  flex: 1,
  fontSize: "16x",
  fontWeight: 700,
});

export const ModalBlurWrapper = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1300,
});
