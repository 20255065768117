export function PeoplesIcon({ color }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill={color ? color : "#464646"}
        fillRule="evenodd"
        d="M4.5 4.333A.667.667 0 104.5 3a.667.667 0 000 1.333zm0 1A1.667 1.667 0 104.5 2a1.667 1.667 0 000 3.333zM11.333 4.333a.667.667 0 100-1.333.667.667 0 000 1.333zm0 1a1.667 1.667 0 100-3.333 1.667 1.667 0 000 3.333zM11.667 7H11c-.736 0-1.333.597-1.333 1.333V10c0 .184.149.333.333.333h2.667c.184 0 .333-.149.333-.333V8.333C13 7.597 12.403 7 11.667 7zM11 6a2.333 2.333 0 00-2.333 2.333V10c0 .736.597 1.333 1.333 1.333h2.667c.736 0 1.333-.597 1.333-1.333V8.333A2.333 2.333 0 0011.667 6H11zM4.426 6.167A2.426 2.426 0 002 8.593v1.252c0 .914.741 1.655 1.656 1.655h1.01v-1h-1.01A.656.656 0 013 9.845V8.593c0-.787.639-1.426 1.426-1.426.575 0 1.108.278 1.439.734l.902-.45a2.777 2.777 0 00-2.34-1.284z"
        clipRule="evenodd"
      ></path>
      <path
        fill={color ? color : "#464646"}
        fillRule="evenodd"
        d="M8 8h-.333a2.333 2.333 0 00-2.334 2.333V12c0 .736.597 1.333 1.334 1.333h2.666c.737 0 1.334-.597 1.334-1.333h-1c0 .184-.15.333-.334.333H6.667A.333.333 0 016.333 12v-1.667C6.333 9.597 6.93 9 7.667 9H8V8zM8.42 6.185a.667.667 0 11.163-.193 2.12 2.12 0 011.06-.605A1.667 1.667 0 108 7.333h.003c.023-.418.17-.817.416-1.148z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
