import { configureStore } from "@reduxjs/toolkit";
import GlobalSlice from "./features/global";
import TicketSlice from "./features/ticket";
import CreateActivitySlice from "./features/create";
import EditActivitySlice from "./features/edit";
import { ModalSlice } from "./features/modal";
// import { PromotionSlice } from "./features/promotion";

export const store = configureStore({
  reducer: {
    TicketSlice,
    GlobalSlice,
    // PromotionSlice: PromotionSlice.reducer,
    ModalSlice: ModalSlice.reducer,
    CreateActivitySlice,
    EditActivitySlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
