export function Peoples2Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill="#C2C2C2"
        fillRule="evenodd"
        d="M4.5 4.535a.667.667 0 100-1.334.667.667 0 000 1.334zm0 1a1.667 1.667 0 100-3.334 1.667 1.667 0 000 3.334zM11.333 4.535a.667.667 0 100-1.334.667.667 0 000 1.334zm0 1a1.667 1.667 0 100-3.334 1.667 1.667 0 000 3.334zM11.666 7.201H11c-.736 0-1.334.597-1.334 1.334V10.2c0 .184.15.333.334.333h2.666c.185 0 .334-.149.334-.333V8.535c0-.737-.597-1.334-1.334-1.334zm-.666-1a2.333 2.333 0 00-2.334 2.334V10.2c0 .737.597 1.333 1.334 1.333h2.666c.737 0 1.334-.596 1.334-1.333V8.535A2.333 2.333 0 0011.666 6.2H11zM4.426 6.368A2.426 2.426 0 002 8.794v1.252c0 .914.741 1.655 1.656 1.655h1.01v-1h-1.01A.656.656 0 013 10.046V8.794c0-.787.639-1.426 1.426-1.426.575 0 1.108.278 1.439.734l.902-.45a2.777 2.777 0 00-2.34-1.284z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#C2C2C2"
        fillRule="evenodd"
        d="M8 8.201h-.333a2.333 2.333 0 00-2.333 2.333v1.667c0 .737.596 1.333 1.333 1.333h2.667c.736 0 1.333-.596 1.333-1.333h-1c0 .184-.15.333-.333.333H6.667a.333.333 0 01-.333-.333v-1.667c0-.736.596-1.333 1.333-1.333H8v-1zM8.42 6.386a.667.667 0 11.163-.193 2.117 2.117 0 011.06-.605A1.667 1.667 0 108 7.535h.003c.023-.418.17-.817.417-1.149z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
