import React from "react";

export const TrumpetIcon = () => {
  return (
    <svg
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18.5"
        cy="18.5"
        r="10"
        transform="rotate(-90 18.5 18.5)"
        fill="#E6E6E6"
        stroke="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7935 21.5114L24.7711 19.5858L18.6164 14.0468L15.6897 19.8111L23.7935 21.5114ZM24.4468 22.8739L26.2566 19.3092L18.2555 12.1084L13.9119 20.6635L21.1296 22.1779L20.0239 24.3558L21.0932 24.8987L22.3452 22.4329L24.4468 22.8739Z"
        fill="#5C65FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3112 13.338L15.6093 11.1882L14.4692 11.5604L15.1711 13.7102L16.3112 13.338ZM14.9267 15.1282L12.788 14.0423L12.245 15.1116L14.3838 16.1975L14.9267 15.1282ZM13.2023 17.588L11.0525 18.2899L11.4247 19.43L13.5745 18.7281L13.2023 17.588Z"
        fill="#5C65FF"
      />
    </svg>
  );
};
