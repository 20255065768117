import StyledSvgIcon, { IconProps } from "./SvgIcon";

export const CloseBulkIcon = (props: IconProps) => {
  return (
    <StyledSvgIcon viewBox="0 0 24 24" id="close-icon" {...props}>
      <path
        data-name="Fill 1"
        d="M14.34,0H5.67C2.28,0,0,2.38,0,5.92v8.17C0,17.62,2.28,20,5.67,20h8.67C17.73,20,20,17.62,20,14.09V5.92C20,2.38,17.73,0,14.34,0"
        transform="translate(2 2)"
        fill="#CED0D9"
      />
      <path
        data-name="Fill 4"
        d="M6.29,5.052,4.511,3.274,6.289,1.5A.875.875,0,0,0,5.052.258L3.273,2.036,1.494.256A.875.875,0,1,0,.256,1.494l1.78,1.78L.26,5.049A.875.875,0,0,0,1.5,6.287L3.273,4.511,5.053,6.29A.875.875,0,0,0,6.29,5.052"
        transform="translate(8.726 8.719)"
        fill="#858A9F"
      />
    </StyledSvgIcon>
  );
};
export const CloseIcon = (props: IconProps) => {
  return (
    <StyledSvgIcon
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.93725 7.99925L2.07025 11.8663C1.64325 12.2937 1.64325 12.9862 2.07025 13.4128L2.58575 13.9283C3.01325 14.3558 3.70575 14.3558 4.13225 13.9283L7.99925 10.0612L11.8662 13.9288C12.2937 14.3562 12.9862 14.3562 13.4127 13.9288L13.9292 13.4132C14.3567 12.9858 14.3567 12.2933 13.9292 11.8667L10.0617 7.99925L13.9287 4.13225C14.3562 3.70475 14.3562 3.01225 13.9287 2.58575L13.4132 2.07025C12.9857 1.64325 12.2933 1.64325 11.8667 2.07025L7.99975 5.93725L4.13275 2.07025C3.70525 1.64325 3.01275 1.64325 2.58625 2.07025L2.07075 2.58575C1.64375 3.01325 1.64375 3.70575 2.07075 4.13225L5.93725 7.99925Z"
        fill="#C5C5C5"
      />
    </StyledSvgIcon>
  );
};

export const CloseCircleIcon = (props: IconProps) => {
  return (
    <StyledSvgIcon viewBox="0 0 30 30" id="close-icon" {...props}>
      <path
        id="Icon_material-cancel"
        data-name="Icon material-cancel"
        d="M18,3A15,15,0,1,0,33,18,14.986,14.986,0,0,0,18,3Zm7.5,20.385L23.385,25.5,18,20.115,12.615,25.5,10.5,23.385,15.885,18,10.5,12.615,12.615,10.5,18,15.885,23.385,10.5,25.5,12.615,20.115,18Z"
        transform="translate(-3 -3)"
        fill="currentColor"
      />
    </StyledSvgIcon>
  );
};
