export function PromotedIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.20859 13.9132L3.77624 12.4809L7.65173 5.74975L11.9397 10.0377L5.20859 13.9132ZM2.37109 12.6667L6.82791 4.92593L7.40923 3.91626L8.23305 4.74008L12.9494 9.4564L13.7732 10.2802L12.7635 10.8615L7.77776 13.7321L9.39779 15.3522L8.6023 16.1477L6.7681 14.3135L5.02274 15.3184L2.37109 12.6667Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.49816 4.44665V2.32533L10.6232 2.32533V4.44665L9.49816 4.44665ZM11.2539 5.63989L12.8449 4.0489L13.6404 4.84439L12.0494 6.43538L11.2539 5.63989ZM13.5078 7.33129H15.6291L15.6291 8.45629L13.5078 8.45629L13.5078 7.33129Z"
        fill="white"
      />
    </svg>
  );
}
