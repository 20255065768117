import React from "react"

interface StyledProps {
    width?: number | string;
    height?: number | string;
    color?: string;
}

export function ScissorsIcon({ color = "#464646", height = 21, width = 24 }: StyledProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_3235_26010)" style={{ fill: color }}>
                <path
                    d="M15.7954 6.23265C16.0596 6.69819 16.4341 7.08108 16.8808 7.32526L16.8794 7.32526C17.2634 7.81364 17.0447 8.43436 16.4269 8.79734C16.4376 8.81626 16.4489 8.84117 16.4597 8.86022C16.3285 8.9375 15.8086 9.22393 15.7036 9.24408C16.2396 9.55358 17.1874 10.1482 17.2126 10.1926C19.0596 9.10758 18.796 8.61518 21.1958 7.2785C21.2827 7.23345 21.3692 7.18657 21.4549 7.13626C23.0082 6.22386 23.9219 4.68671 23.9219 3.24406C23.9219 2.70453 23.7942 2.17843 23.5243 1.70276C22.5329 -0.0442727 19.9993 -0.444006 17.8649 0.809646C16.3115 1.72205 15.3978 3.25395 15.3978 4.6966C15.3978 5.23613 15.5255 5.75722 15.7954 6.23265ZM18.5849 2.07856C20.0036 1.24515 21.6946 1.40851 22.2773 2.43531C22.413 2.67462 22.4819 2.94664 22.4819 3.24406C22.4819 4.19931 21.7798 5.25347 20.7348 5.86735C19.3162 6.70076 17.6251 6.53727 17.0425 5.51047C16.9068 5.27129 16.8379 4.99915 16.8379 4.70197C16.8378 3.74672 17.5399 2.69244 18.5849 2.07856Z"
                />
                <path
                    d="M13.2773 12.403C13.0845 12.3334 12.8799 12.2389 12.6625 12.1112L10.5182 10.8516L0.076144 16.9854C0.913383 17.8094 3.12366 18.3675 5.86026 16.7598L13.2773 12.403Z"
                />
                <path
                    d="M23.5243 19.2959C23.6998 18.9867 23.8152 18.6559 23.8744 18.3142C24.1499 16.7247 23.2114 14.8944 21.4548 13.8625C21.3691 13.8121 21.2826 13.7655 21.1957 13.7204C18.7959 12.3839 19.0595 11.8916 17.2126 10.8067C17.1987 10.8312 17.1841 10.8567 17.17 10.8818L5.8613 4.23881C3.12446 2.63121 0.914185 3.18918 0.0770645 4.01318L12.9636 11.583C14.5571 12.5189 15.1335 11.3538 16.0285 11.8796C16.1677 11.9613 16.3125 12.0465 16.4597 12.1333C16.4488 12.1525 16.4376 12.183 16.4269 12.202C17.0447 12.5649 17.2636 13.3081 16.8795 13.6744L16.8809 13.6744C16.4341 13.9186 16.0595 14.301 15.7954 14.7665C15.6198 15.0758 15.5045 15.4008 15.4453 15.7428C15.1698 17.3323 16.1083 19.16 17.8649 20.1917C19.9992 21.4455 22.5329 21.043 23.5243 19.2959ZM13.5606 11.1819C13.2163 10.9796 13.0984 10.5316 13.2971 10.1812C13.4958 9.83092 13.9362 9.71078 14.2806 9.91308C14.6249 10.1154 14.7431 10.5635 14.5441 10.9138C14.3453 11.264 13.9049 11.3841 13.5606 11.1819ZM18.5848 18.9203C17.4126 18.2316 16.6885 17.0001 16.8633 15.9917C16.8945 15.812 16.9547 15.6428 17.0422 15.4882C17.625 14.4613 19.3158 14.2978 20.7347 15.1313C21.907 15.8199 22.6309 17.0515 22.4562 18.0597C22.425 18.2393 22.3648 18.4087 22.2771 18.5634C21.9303 19.1746 21.2669 19.3624 20.9025 19.42C20.1677 19.5358 19.3229 19.3538 18.5848 18.9203Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_3235_26010">
                    <rect
                        width={width}
                        height={height}
                        fill="white"
                        transform="translate(24) rotate(90)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}