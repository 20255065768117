import axios from "axios";
import { auth } from "../../firebase";

const httpClient = axios.create();

httpClient.interceptors.request.use(async (request) => {
  let token = await auth.currentUser?.getIdToken(true);
  request.headers.set("Authorization", token);

  return request;
});

export default httpClient;
