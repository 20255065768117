export default function JoinEventIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.75 8C8.13071 8 9.25 6.88071 9.25 5.5C9.25 4.11929 8.13071 3 6.75 3C5.36929 3 4.25 4.11929 4.25 5.5C4.25 6.88071 5.36929 8 6.75 8Z"
        fill="#5C65FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5 9C14.567 9 13 10.567 13 12.5V15C13 16.1046 13.8954 17 15 17H19C20.1046 17 21 16.1046 21 15V12.5C21 10.567 19.433 9 17.5 9H16.5Z"
        fill="#5C65FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.6395 9.25C4.62946 9.25 3 10.8795 3 12.8895V14.7667C3 16.1382 4.11183 17.25 5.48333 17.25H7V15.75C7 15.75 7 15 7 14.5C7 13.2499 8 12 8.64685 11.6766L10 11C9.24079 9.80948 8.07123 9.25 6.6395 9.25Z"
        fill="#5C65FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 12H11.5C9.567 12 8 13.567 8 15.5V18C8 19.1046 8.89543 20 10 20H14C15.1046 20 16 19.1046 16 18H14.5C13.5 18 12 17.5 12 16V14.5V13.5V12Z"
        fill="#5C65FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.629 9.27742C12.7175 9.15872 12.8733 8.98754 12.8733 8.98754C12.8733 8.98754 13.0259 8.83494 13.1085 8.76417C13.5013 8.42748 13.9675 8.19431 14.4649 8.08026C14.2653 6.89939 13.2377 6 12 6C10.6193 6 9.5 7.11929 9.5 8.5C9.5 9.88071 10.6193 11 12 11L12.0048 11C12.0392 10.3732 12.2015 9.85113 12.629 9.27742Z"
        fill="#5C65FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.0303 0.46967C23.3232 0.762563 23.3232 1.23744 23.0303 1.53033L17.5303 7.03033C17.2374 7.32322 16.7626 7.32322 16.4697 7.03033L14.4697 5.03033C14.1768 4.73744 14.1768 4.26256 14.4697 3.96967C14.7626 3.67678 15.2374 3.67678 15.5303 3.96967L17 5.43934L21.9697 0.46967C22.2626 0.176777 22.7374 0.176777 23.0303 0.46967Z"
        fill="#5C65FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.3419 3.39031C17.9542 3.14319 17.4938 3 17 3C16.4901 3 16.016 3.1526 15.6206 3.41462C15.7148 3.46854 15.8034 3.5357 15.8838 3.61612L17 4.73223L18.3419 3.39031ZM14.5159 5.78368C14.6567 7.03083 15.7152 8 17 8C18.2847 8 19.3432 7.03083 19.484 5.78368L17.8838 7.38388C17.3957 7.87204 16.6042 7.87204 16.1161 7.38388L14.5159 5.78368Z"
        fill="#5C65FF"
      />
    </svg>
  );
}
