import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useContext,
} from "react";
import { auth } from "../firebase";
import { IDoer } from "../types/activity";
import { useDoer } from "../hooks/useActivity";

export interface AuthContextType {
  isLogin: boolean;
  signOut: () => void;
  doer: IDoer | undefined;
  getDoer: () => void;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { data: doer, refetch: getDoer } = useDoer(
    auth?.currentUser?.uid || ""
  );

  const [isLogin, setIsLogin] = useState(false);
  useEffect(() => {
    auth?.onAuthStateChanged(async (user) => {
      if (user) {
        setIsLogin(true);
        getDoer();
      } else {
        setIsLogin(false);
      }
    });
  }, [auth]);

  const signOut = () => {
    auth.signOut();
  };

  return (
    <AuthContext.Provider value={{ isLogin, signOut, doer, getDoer }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
