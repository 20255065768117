export default function ModeratorsIcon() {
  return (
    <svg
      width="22"
      height="17"
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.0461 5.99501V8.30906C15.0461 9.03022 14.5846 9.67047 13.9005 9.89852C12.0477 10.5161 10.0445 10.5161 8.19172 9.89852C7.50757 9.67047 7.0461 9.03022 7.0461 8.30906V5.95345C7.88607 6.41817 8.9288 6.81972 10.0913 6.98193C11.6224 7.19557 13.3479 6.98968 15.0461 5.99501ZM15.0461 3.54585V3.28141C15.0461 3.06442 14.9183 2.86779 14.72 2.77966C12.381 1.74011 9.71116 1.74011 7.37218 2.77966C7.17389 2.86779 7.0461 3.06442 7.0461 3.28141V3.54579C7.70516 4.10515 8.92413 4.7997 10.3677 5.00112C11.7776 5.19785 13.4217 4.93081 15.0461 3.54585ZM5.0461 3.28141C5.0461 2.27404 5.63936 1.36117 6.5599 0.952037C9.41601 -0.317345 12.6762 -0.317346 15.5323 0.952037C16.4528 1.36117 17.0461 2.27404 17.0461 3.28141V7.50682L20.183 9.07525C22.284 10.1257 22.284 13.124 20.183 14.1745C14.4312 17.0504 7.66103 17.0504 1.90923 14.1745C-0.191754 13.124 -0.191747 10.1257 1.90924 9.07525L5.0461 7.50682V3.28141ZM5.28831 9.62179L2.80366 10.8641C2.17677 11.1776 2.17677 12.0722 2.80366 12.3856C7.9924 14.98 14.0998 14.98 19.2885 12.3856C19.9154 12.0722 19.9154 11.1776 19.2885 10.8641L16.8039 9.62178C16.4185 10.6291 15.5989 11.4406 14.5329 11.7959C12.2696 12.5503 9.82261 12.5503 7.55927 11.7959C6.49328 11.4406 5.67373 10.6291 5.28831 9.62179Z"
        fill="#464646"
      />
    </svg>
  );
}
