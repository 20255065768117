import styled from "@emotion/styled";
import React from "react";

interface StyledProps {
  width?: number | string;
  color?: string;
  height?: number | string;
  mdIcon?: boolean;
}

export type IconProps = React.SVGProps<SVGSVGElement> & StyledProps;

export const StyledSvgIcon = styled.svg<StyledProps>(
  ({ width, height, color }) => ({
    width: width ?? 24,
    height: height ?? 24,
    color: color 
  })
);

export default StyledSvgIcon;
