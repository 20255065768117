import React from "react";

export const VisitBlackIcon = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33325 11.5C5.88554 11.5 6.33325 11.9477 6.33325 12.5V24.5C6.33325 25.4205 7.07944 26.1667 7.99992 26.1667H23.9999C24.9204 26.1667 25.6666 25.4205 25.6666 24.5V12.5C25.6666 11.9477 26.1143 11.5 26.6666 11.5C27.2189 11.5 27.6666 11.9477 27.6666 12.5V24.5C27.6666 26.525 26.025 28.1667 23.9999 28.1667H7.99992C5.97487 28.1667 4.33325 26.525 4.33325 24.5V12.5C4.33325 11.9477 4.78097 11.5 5.33325 11.5Z"
        fill="#464646"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9399 5.65795C16.4379 5.41885 15.8389 5.62608 15.6021 6.12082C15.3652 6.61556 15.5801 7.21046 16.0821 7.44956L19.0993 8.88672C17.1093 9.12584 15.7535 10.1543 14.9034 11.4193C13.9668 12.8131 13.6667 14.4494 13.6667 15.5485V23.1675C13.6667 23.7198 14.1145 24.1675 14.6667 24.1675C15.219 24.1675 15.6667 23.7198 15.6667 23.1675V15.5485C15.6667 14.7428 15.9 13.522 16.5634 12.5348C17.1864 11.6078 18.2082 10.845 19.9627 10.8343L18.4236 14.0712C18.1881 14.5666 18.4046 15.161 18.9072 15.3988C19.4099 15.6365 20.0083 15.4277 20.2438 14.9323L22.5202 10.1445C22.912 9.32048 22.5533 8.33171 21.7182 7.93395L16.9399 5.65795Z"
        fill="#464646"
      />
    </svg>
  );
};
