export function MessageIcon() {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6665 1.5H6.6665C4.18122 1.5 2.1665 3.51472 2.1665 6V12C2.1665 14.4853 4.18122 16.5 6.6665 16.5H17.1665V6C17.1665 3.51472 15.1518 1.5 12.6665 1.5ZM6.6665 0C3.3528 0 0.666504 2.68629 0.666504 6V12C0.666504 15.3137 3.3528 18 6.6665 18H18.6665V6C18.6665 2.68629 15.9802 0 12.6665 0H6.6665Z"
        fill="#464646"
      />
    </svg>
  );
}
