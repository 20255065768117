import {
  ModalBlur,
  MModalBodyContainer,
  ModalTitleBar,
  ModalTitle,
  ModalBlurWrapper,
} from "./styled.modals";
import { ModalCloseButton } from "./modal.button";
import { closeModal } from "../../../lib/features/modal";
import { Modal } from "../../../types";
import { RootState } from "../../../lib/store";
import { connect } from "react-redux";

const ModalComponent = ({
  Body,
  Container = MModalBodyContainer,
  title,
  titleProps,
  id,
  closeButton,
  closeModal,
  navbarModal,
  clickOutside = true,
  ...rest
}: Modal) => {
  const handleClose = () => {
    closeModal?.(id);
  };

  const handleClickOutside = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent> & { target: { id: string } }
  ) => {
    if (
      ["modal-blur-wrapper", "modal-blur-component"].includes(e.target.id) &&
      clickOutside
    )
      handleClose();
  };
  return (
    <ModalBlurWrapper id="modal-blur-wrapper" onClick={handleClickOutside}>
      <ModalBlur
        top={navbarModal ? "55px" : "0"}
        id="modal-blur-component"
        onClick={handleClickOutside}
      >
        <Container>
          {(title || closeButton) && (
            <ModalTitleBar {...titleProps}>
              <ModalTitle>{title ?? ""}</ModalTitle>
              {closeButton && (
                <ModalCloseButton handleCloseModal={handleClose} />
              )}
            </ModalTitleBar>
          )}
          <Body id={id} closeModal={handleClose} {...rest} />
        </Container>
      </ModalBlur>
    </ModalBlurWrapper>
  );
};

const ModalsList = ({
  modals,
  closeModal,
}: {
  modals: Modal[];
  closeModal: (id?: string) => void;
}) => {
  return (
    <>
      {modals?.map((modal, index) => (
        <ModalComponent key={index} closeModal={closeModal} {...modal} />
      ))}
    </>
  );
};

const mapStateToProps = ({ ModalSlice: { modals } }: RootState) => ({
  modals,
});

const mapDispatchToProps = { closeModal };
export const Modals = connect(mapStateToProps, mapDispatchToProps)(ModalsList);
